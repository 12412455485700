import { createSlice } from "@reduxjs/toolkit";
import { productMasterBulkGetAllACTION, productMasterBulkUpdateACTION } from "../../actions/settings/productMasterBulkAction";
import { Enums } from "../../../helper/constants/emuns-const";

const INITIAL_STATE = {

    productBulk: {
        data: { records: [] },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    updateBulk: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

};

const productBulkReducer = createSlice({
    name: "productBulk",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetProductBulkUpdateState(state, action) {
            state.updateBulk = INITIAL_STATE.updateBulk;
        }
    },

    extraReducers: (builder) => {
        builder.addCase(productMasterBulkGetAllACTION.fulfilled, (state, action) => {
            state.productBulk.data = action.payload.data;
            state.productBulk.apiMsg.status = action.meta.requestStatus;
            state.productBulk.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(productMasterBulkGetAllACTION.pending, (state, action) => {
            state.productBulk.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(productMasterBulkGetAllACTION.rejected, (state, action) => {
            state.productBulk.apiMsg.message = action.error.message;
            state.productBulk.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(productMasterBulkUpdateACTION.fulfilled, (state, action) => {
            console.log("action.meta.arg", action.meta.arg);
            console.log("state.productBulk.data.records", state.productBulk.data.records);

            const updatedProducts = action.meta.arg.products;

            // Check actionType
            if (action.meta.arg.actionType === 1) {
                state.productBulk.data.records = state.productBulk.data.records.map((record) => {
                    const updatedProduct = updatedProducts.find(
                        (product) => product.productID === record.productID
                    );

                    if (updatedProduct) {
                        return {
                            ...record,
                            active: true 
                        };
                    }
                    return record;
                });
            } else if (action.meta.arg.actionType === 2) {
                state.productBulk.data.records = state.productBulk.data.records.map((record) => {
                    const updatedProduct = updatedProducts.find(
                        (product) => product.productID === record.productID
                    );

                    if (updatedProduct) {
                        return {
                            ...record,
                            active: false 
                        };
                    }
                    return record;
                });
            } else if (action.meta.arg.actionType === 3) {
                state.productBulk.data.records = state.productBulk.data.records.filter(
                    (record) => !updatedProducts.some((product) => product.productID === record.productID)
                );
            } else {
                state.productBulk.data.records = state.productBulk.data.records.map((record) => {
                    const updatedProduct = updatedProducts.find(
                        (product) => product.productID === record.productID
                    );
                    return updatedProduct
                        ? {
                            ...record,
                            printersIDs: updatedProduct.printersIDs,
                            kitchenDisplayIDs: updatedProduct.kitchenDisplayIDs,
                        }
                        : record; 
                });
            }

            state.productBulk.apiMsg.status = action.meta.requestStatus;
            state.productBulk.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(productMasterBulkUpdateACTION.pending, (state, action) => {
            state.productBulk.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(productMasterBulkUpdateACTION.rejected, (state, action) => {
            state.productBulk.apiMsg.message = action.error.message;
            state.productBulk.apiMsg.status = action.meta.requestStatus;
        });
    }
});

export const { RESET, ResetProductBulkUpdateState } = productBulkReducer.actions;
export default productBulkReducer.reducer;